import(/* webpackMode: "eager", webpackExports: ["ClientPolyfill"] */ "/vercel/path0/apps/dashboard/src/app/client-polyfill.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/dashboard/src/app/pageview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider","UserIdentity"] */ "/vercel/path0/apps/dashboard/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DevErrorNotifier"] */ "/vercel/path0/apps/dashboard/src/components/dev-error-notifier.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouterProvider"] */ "/vercel/path0/apps/dashboard/src/components/router.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyleLink"] */ "/vercel/path0/apps/dashboard/src/components/style-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/dashboard/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VersionAlerter"] */ "/vercel/path0/apps/dashboard/src/components/version-alerter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18_5clp6zxuxxboanxynkwygw3dqu/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-_bwxiyieurxyrzexhwwv35od5ga/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.0_next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.0_next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/dashboard/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/action-dialog.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/copy-button.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/data-table/cells.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/data-table/data-table.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/data-table/toolbar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/data-table/view-options.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/accordion.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/aspect-ratio.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/avatar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/calendar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/checkbox.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/collapsible.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/command.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/context-menu.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/dialog.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/dropdown-menu.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/form.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/hover-card.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/inline-code.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/input-otp.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/label.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/menubar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/navigation-menu.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/password-input.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/popover.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/progress.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/radio-group.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/scroll-area.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/select.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/separator.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/sheet.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/skeleton.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/slider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/switch.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/tabs.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/toast.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/toaster.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/toggle-group.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/tooltip.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack-ui/dist/components/ui/use-toast.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/components-page/account-settings.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/components-page/auth-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/components-page/email-verification.js");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorPage"] */ "/vercel/path0/packages/stack/dist/esm/components-page/error-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/components-page/forgot-password.js");
;
import(/* webpackMode: "eager", webpackExports: ["MagicLinkCallback"] */ "/vercel/path0/packages/stack/dist/esm/components-page/magic-link-callback.js");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthCallback"] */ "/vercel/path0/packages/stack/dist/esm/components-page/oauth-callback.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/components-page/password-reset.js");
;
import(/* webpackMode: "eager", webpackExports: ["SignOut"] */ "/vercel/path0/packages/stack/dist/esm/components-page/sign-out.js");
;
import(/* webpackMode: "eager", webpackExports: ["SignUp"] */ "/vercel/path0/packages/stack/dist/esm/components-page/sign-up.js");
;
import(/* webpackMode: "eager", webpackExports: ["TeamInvitation"] */ "/vercel/path0/packages/stack/dist/esm/components-page/team-invitation.js");
;
import(/* webpackMode: "eager", webpackExports: ["CredentialSignIn"] */ "/vercel/path0/packages/stack/dist/esm/components/credential-sign-in.js");
;
import(/* webpackMode: "eager", webpackExports: ["CredentialSignUp"] */ "/vercel/path0/packages/stack/dist/esm/components/credential-sign-up.js");
;
import(/* webpackMode: "eager", webpackExports: ["IframePreventer"] */ "/vercel/path0/packages/stack/dist/esm/components/iframe-preventer.js");
;
import(/* webpackMode: "eager", webpackExports: ["MagicLinkSignIn"] */ "/vercel/path0/packages/stack/dist/esm/components/magic-link-sign-in.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/components/message-cards/message-card.js");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthButtonGroup"] */ "/vercel/path0/packages/stack/dist/esm/components/oauth-button-group.js");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthButton"] */ "/vercel/path0/packages/stack/dist/esm/components/oauth-button.js");
;
import(/* webpackMode: "eager", webpackExports: ["SelectedTeamSwitcher"] */ "/vercel/path0/packages/stack/dist/esm/components/selected-team-switcher.js");
;
import(/* webpackMode: "eager", webpackExports: ["UserButton"] */ "/vercel/path0/packages/stack/dist/esm/components/user-button.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/stack/dist/esm/providers/stack-provider-client.js");
;
import(/* webpackMode: "eager", webpackExports: ["StackTheme"] */ "/vercel/path0/packages/stack/dist/esm/providers/theme-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationProviderClient"] */ "/vercel/path0/packages/stack/dist/esm/providers/translation-provider-client.js");
